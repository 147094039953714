
import VueDatepickerLocal from 'vue-datepicker-local'
export default {
	name: 'workingTime',
	data() {
		return {
			sex: 'girl',
			list: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			restaurants: [],
			state1: '',
			options: [{
				label: '全部',
				value: ''
			}, {
				label: '初始状态',
				value: 0
			}, {
				label: '待启动',
				value: 1
			}, {
				label: '进行中',
				value: 2
			}, {
				label: '中心关闭',
				value: 3
			},],
			state: '',
			department: '',
			departmentList: [],
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.department = Number(this.$route.params.id);
		this.getProMang()
		//			this.getDepartmentList()
	},

	filters: {
		stateFilter(data) {
			if (data == 1) {
				data = '待启动';
			} else if (data == 2) {
				data = '进行中';
			} else if (data == 3) {
				data = '中心关闭';
			} else {
				data = '初始状态'
			}
			return data;
		}
	},
	components: { VueDatepickerLocal },
	methods: {
		getProMang() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/projectSupervisionReport', {
				page: _this.currentPage,
				departmentId: _this.department ? _this.department : '',
				state: _this.state,
				projectName: _this.state1,
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.list = response.data.data.objArray;
						_this.total = response.data.data.total;
						_this.pageSize = response.data.data.pageSize;
						_this.currentPage = response.data.data.currentPage;
					} else if (response.data.code == 401) {
						_this.$router.push('/')
					}
				})
			this.getDepartmentList()
		},
		//科室下拉框
		getDepartmentList() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/findDepartmentList', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.departmentList = response.data.data.array;
					_this.departmentList.unshift({ 'id': 0, 'name': '全部' })
					//						_this.department=_this.departmentList[_this.$route.params.id].name

				}
			})
		},
		//搜索框
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getProMang()
		},
		//点击跳转详情
		inspectParticulars(id) {
			this.$router.push({
				name: 'inspectParticulars',
				params: {
					id: id
				}
			})
		},
		//导出
		templateDownLoad() {
			var _this = this;
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(255, 255, 255, 0.7)'
			});
			_this.$http.post(_this.craUrl + '/projectSupervisionReport', {
				page: _this.currentPage,
				departmentId: _this.department ? _this.department : '',
				state: _this.state,
				projectName: _this.state1,
				isAll: 1
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						setTimeout(function () {
							loading.close();
						}, 1500)
						require.ensure([], () => {
							const {
								export_json_to_excel
							} = require('../../vendor/Export2Excel');
							const tHeader = ['项目名称', '科室名称', '申办方', 'CRO公司', '启动时间', '关闭时间', '监查工时', '稽查工时', '协同监查工时', '交接工时', '当前状态'];
							const filterVal = ['shortName', 'depts', 'sponsor', 'cro', 'start', 'end', 'jctime', 'auditTime', 'coordinationTime', 'handoverTime', 'stateStr'];
							const data = _this.formatJson(filterVal, response.data.data.objArray);
							export_json_to_excel(tHeader, data, '监查工时详情列表');
						})
					} else {
						loading.close();
						_this.defeated('导出失败，请稍后再试')
					}
				})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	},
}
